import React, { useState, useEffect } from 'react'
import axios from "../../../../api/axios";
import {Alert} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import {useParams} from "react-router-dom";


const ALERTS = '/api/rtm/port_alerts/';


const Portfolio_alerts = () => {
    const params = useParams()
    const [choosenAlertTab, setAlertTab] = useState("create");
    const [activeAlerts, setactiveAlerts] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [createAlertflex1, setAlertflex1] = useState("Upper Threshold");
    const [createAlertflex2, setAlertflex2] = useState("Lower Threshold");
    const [alerttype, setAlertype] = useState('Alert1');
    const [alertcrit, setAlercrit] = useState('Low');
    const [alertdesc, setAlertdesc] = useState('');
    const [alertthreshold, setAlertthreshold] = useState('');
    const [alertDate, setAlerDate] = useState('');
    const [alertThreshold2, setAlerThreshold2] = useState('');

    useEffect(() => {
        setErrMsg('');
    }, [alertDate, alertThreshold2, alertdesc, alertthreshold])

    useEffect(() => {
        async function fetchActiveAlerts() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ALERTS  + params.portId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setactiveAlerts(await response?.data);
            }
            catch (e)
            {
                console.log(e);
            }
        }


        fetchActiveAlerts();

    }, [])

    const sendcreateAlert = async (e) => {
        e.preventDefault();
        async function fetchActiveAlerts() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ALERTS  + params.portId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setactiveAlerts(await response?.data);
            }
            catch (e)
            {
                console.log(e);
            }
        }

        if (alerttype == "Alert1" && alertthreshold == "" && alertThreshold2=="")
        {
            setErrMsg('Please Fill both Thresholds!');
        }
        else if(alerttype == "Alert2" && alertthreshold == "" && alertThreshold2=="")
        {
            setErrMsg('Please Fill both Thresholds!');
        }
        else if(alertdesc == "" || alertthreshold == "")
        {
            setErrMsg('Please enter a Description and a Threshold!');
        }


        if (errMsg == "")
        {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }

                const response = await axios.post(ALERTS, {
                        "portfolio_account": params.portId,
                        "alert_type": alerttype,
                        "alert_desc": alertdesc,
                        "criticality": alertcrit,
                        "threshold1": alertthreshold,
                        "threshold2": alertThreshold2,
                        "alert_action": "none",
                    },
                    {headers: {
                            'Content-Type': 'application/json',
                            'Authorization': ['Bearer ', token].join('')
                        }});
                fetchActiveAlerts();
                document.getElementById('alertdesc').value = "";
                document.getElementById('threshold1').value = "";
                document.getElementById('threshold2').value = "";


            }
            catch (err)
            {
                console.log(err);
            }

        }


    }



    const removeActiveAlert = (id) => {

        var token = "";
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('Token');
        }
        axios.delete(ALERTS  + id, {headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ['Bearer ', token].join('')
            }}).then(res => {
            const del = activeAlerts.filter(item => id !== item.alert_id)
            setactiveAlerts(del)
        });
    }



    const renderActiveAlertsHeader = ()=> {
        let headerElement = ['#', 'Alert Type', 'Description', 'Criticality', 'Threshold', 'Threshold 2', 'Action', 'Delete']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }



    const renderActiveAlertsBody = () => {
        return activeAlerts && activeAlerts.map(({ alert_id, alert_account, alert_type, alert_desc, criticality, threshold1, threshold2, alert_action}) => {
            return (
                <tr key={alert_id}>
                    <td>{alert_id}</td>
                    <td>{alert_type}</td>
                    <td>{alert_desc}</td>
                    <td>{criticality}</td>
                    <td>{threshold1}</td>
                    <td>{threshold2}</td>
                    <td>{alert_action}</td>
                    <td className='opration'>
                        <button className='button' onClick={() => removeActiveAlert(alert_id)}>Delete</button>
                    </td>
                </tr>
            )
        })
    }


    const changeAlertType = (e) => {

        setAlertype(e.target.value);
        if (e.target.value == "Alert1" || e.target.value == "Alert2")
        {
            console.log(e.target.value);
            setAlertflex1("Upper Threshold");
            setAlertflex2("Lower Threshold");
            var x = document.getElementById("thresholddiv2");
            x.style.display = "block";
        }
        else
        {
            setAlertflex2("Threshold");
            var x = document.getElementById("thresholddiv2");
            x.style.display = "none";
        }
    }


    return (
        <React.Fragment>
            <div className="card-inner">
                <div className="card-title-group">
                    <div className="card-title card-title-sm">
                        <h6 className="title">Alerts</h6>
                    </div>
                    <div className="card-tools">
                        <ul className="card-tools-nav">
                            <li className={choosenAlertTab === "create" ? "active" : ""} onClick={() => setAlertTab("create")}>
                                <a
                                    href="#create"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Create Alert</span>
                                </a>
                            </li>
                            <li className={choosenAlertTab === "activealerts" ? "active" : ""} onClick={() => setAlertTab("activealerts")}>
                                <a
                                    href="#activealerts"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Active Alerts</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {choosenAlertTab == "create" ? (
                <div className="card-inner">
                    <form action="#" onSubmit={sendcreateAlert}>
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="full-name-1">Alert Type</label>
                                    <div className="form-control-wrap">

                                        <select name="alertype" className="form-control" id="alertype" onChange={(e) => changeAlertType(e)}>
                                            <option value="Alert1">Weekly Profit/Loss Target % reached</option>
                                            <option value="Alert2">Monthly Profit/Loss Target % reached</option>
                                            <option value="Alert3">Total Exposore above Lot Threshold</option>
                                            <option value="Alert4">Directional Exposure above Lot Threshold</option>
                                            <option value="Alert5">Drawdown above Threshold %</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="email-address-1">Criticality</label>
                                    <div className="form-control-wrap">

                                        <select name="criticality" className="form-control" id="criticality" onChange={(e) => setAlercrit(e.target.value)}>
                                            <option value="Low">Low</option>
                                            <option value="Medium">Medium</option>
                                            <option value="High">High</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="phone-no-1">Description</label>
                                    <div className="form-control-wrap"><input type="text" className="form-control"
                                                                              id="alertdesc" onChange={(e) => setAlertdesc(e.target.value)}/></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="pay-amount-1">{createAlertflex2}</label>
                                    <div className="form-control-wrap"><input type="text" className="form-control"
                                                                              id="threshold1" onChange={(e) => setAlertthreshold(e.target.value)}/></div>
                                </div>
                            </div>


                            <div className="col-lg-6" id="thresholddiv2" >
                                <div className="form-group"><label className="form-label" htmlFor="pay-amount-1">{createAlertflex1}</label>
                                    <div className="form-control-wrap"><input type="text" className="form-control"
                                                                              id="threshold2" onChange={(e) => setAlerThreshold2(e.target.value)}/></div>
                                </div>
                            </div>


                            {errMsg && (
                                <div className="col-12">
                                    <Alert color="danger" className="alert-icon">
                                        {" "}
                                        <Icon name="alert-circle" /> {errMsg}
                                    </Alert>
                                </div>
                            )}
                            <div className="col-12">
                                <div className="form-group">
                                    <button className="btn btn-lg btn-primary" type="submit">Create Alert</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            ):  (
                <div className="card-inner">
                    <table className="table">
                        <thead>
                        <tr>{renderActiveAlertsHeader()}</tr>
                        </thead>
                        <tbody>
                        {renderActiveAlertsBody()}
                        </tbody>
                    </table>
                </div>
            )}

        </React.Fragment>
    )
}


export default Portfolio_alerts