import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(JSON.parse(localStorage.getItem('auth')))
    const location = useLocation()

    useEffect(() => {
        setAuth(JSON.parse(localStorage.getItem('auth')))

    }, [location])


    const memoedValue = useMemo(() => {
        return { auth, setAuth }

    }, [auth, location])



    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
