import { Outlet } from "react-router-dom"
import { useContext,useEffect, useState } from "react";

const Layout = () => {
    const [themeState] = useState({
        main: "dark",
        sidebar: "dark",
        header: "dark",
        skin: "dark",
    });
    useEffect(() => {

        document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${
            themeState.skin === "dark" ? "dark-mode" : ""
        }`;
    }, []);
    return (
        <div className="nk-app-root">
        <main className="App">

            <Outlet />
        </main>
        </div>
    )
}

export default Layout