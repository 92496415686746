import React, { useState, Outlet } from "react";
import Head from '../../components/head/Head';
import Content from '../../components/content/Content';


import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockTitle,
    PreviewAltCard,
    Icon,
    Button,
    Row,
    Col,
} from "../../assets/components/Component";

import IB_Payment_Table from "./components/Table_existing_IB_payments";
import Form_new_IB_Payment from "./components/Form_new_IB_Payment";



const IB_Management = () => {
    const [sm, updateSm] = useState(false);
    console.log("test");
    return (
        <React.Fragment>
            <Head title="IB Management" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Partner Management</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>Here you find a List of all weekly IB Payments. You can add new Payments and delete existing ones.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="more-v" />
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">

                                        <li className="nk-block-tools-opt">
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="transparent" className="btn btn-primary btn-icon dropdown-toggle">
                                                    <Icon name="plus"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <ul className="link-list-opt no-bdr">
                                                        <li>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                            >
                                                                <Icon name="user-add-fill"></Icon>
                                                                <span>Add IB Payment</span>
                                                            </DropdownItem>
                                                        </li>

                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Row className="g-gs">
                        <Col lg="12">
                            <Card className="card-bordered card-full">
                                <IB_Payment_Table />
                            </Card>
                        </Col>
                    </Row>
                    <Row className="g-gs">
                        <Col lg="12">
                            <Card className="card-bordered card-full">
                                <Form_new_IB_Payment />
                            </Card>
                        </Col>
                    </Row>
                </Block>
            </Content>

        </React.Fragment>
    );
};

export default IB_Management;
