import React, { useEffect, useState } from "react";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../../assets/components/Component";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    PreviewCard,
    ReactDataTable,
} from "../../../../assets/components/Component";
import { Link, useParams } from "react-router-dom";
import axios from "../../../../api/axios";
const OPEN_TRADES = '/api/rtm/open_positions/';
const PENDING_TRADES = '/api/rtm/pending_positions/';
const CLOSED_TRADES = '/api/rtm/closed_positions/';


const Trades_Table = () => {

    const [TradesTab, setTradesTab] = useState("Closed");
    const [open_trades, setOpentrades] = useState([]);
    const [pending_trades, setPendingtrades] = useState([]);
    const [closed_trades, setClosedtrades] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const dataTableColumnsopen = [
        {
            name: "Ticket",
            selector: (row) => row.ticket,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Symbol",
            selector: (row) => row.symbol,
            sortable: true,
            hide: 370,
        },
        {
            name: "Date",
            selector: (row) => row.opentime,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Lots",
            selector: (row) => row.lots,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Price",
            selector: (row) => row.openprice,
            sortable: true,
            hide: "md",
        },
        {
            name: "SL",
            selector: (row) => row.stoploss,
            sortable: true,
            hide: "md",
        },
        {
            name: "TP",
            selector: (row) => row.takeprofit,
            sortable: true,
            hide: "md",
        },
        {
            name: "Profit",
            selector: (row) => row.profit,
            sortable: true,
            hide: "md",
        },
    ];
    const dataTableColumnsclose = [
        {
            name: "Ticket",
            selector: (row) => row.ticket,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Symbol",
            selector: (row) => row.symbol,
            sortable: true,
            hide: 370,
        },
        {
            name: "Open Date",
            selector: (row) => row.opentime,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Lots",
            selector: (row) => row.lots,
            sortable: true,
            hide: "sm",
        },
        {
            name: "SL",
            selector: (row) => row.stoploss,
            sortable: true,
            hide: "md",
        },
        {
            name: "Comment",
            selector: (row) => row.comment,
            sortable: true,
            hide: "md",
        },
        {
            name: "Comission",
            selector: (row) => row.comission,
            sortable: true,
            hide: "md",
        },
        {
            name: "Profit",
            selector: (row) => row.profit,
            sortable: true,
            hide: "md",
        },
    ];
    const dataTableColumnspending = [
        {
            name: "Ticket",
            selector: (row) => row.ticket,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Symbol",
            selector: (row) => row.symbol,
            sortable: true,
            hide: 370,
        },
        {
            name: "Date",
            selector: (row) => row.opentime,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Lots",
            selector: (row) => row.lots,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Price",
            selector: (row) => row.openprice,
            sortable: true,
            hide: "md",
        },
        {
            name: "SL",
            selector: (row) => row.stoploss,
            sortable: true,
            hide: "md",
        },
        {
            name: "TP",
            selector: (row) => row.takeprofit,
            sortable: true,
            hide: "md",
        },

    ];
    const [roles, setRoles] = useState([]);
    const [tabledata, setTableData] = useState(closed_trades);
    const [tablecolumns, setTableColumns] = useState(dataTableColumnsclose);

    const params = useParams()

    useEffect(() => {
        async function fetchOpenTrades() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                    setRoles(localStorage.getItem('Roles'));
                }
                const response = await axios.get(OPEN_TRADES + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setOpentrades(await response?.data);

            }
            catch (e) {
                console.log(e);
            }
        }
        async function fetchPendingTrades() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(PENDING_TRADES + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setPendingtrades(await response?.data);

            }
            catch (e) {
                console.log(e);
            }
        }
        async function fetchClosedTrades() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(CLOSED_TRADES + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setClosedtrades(await response?.data);
                setTableData(await response?.data);


            }
            catch (e) {
                console.log(e);
            }
        }
        fetchClosedTrades();
        fetchOpenTrades();
        fetchPendingTrades();


    }, []);



    useEffect(() => {

        if (TradesTab == "Open") {
            setTableData(open_trades);
            setTableColumns(dataTableColumnsopen);
        } else if (TradesTab == "Closed") {
            setTableData(closed_trades);
            setTableColumns(dataTableColumnsclose);
        } else {
            setTableData(pending_trades);
            setTableColumns(dataTableColumnspending);
        }

    }, [TradesTab]);


    useEffect(() => {
        if (closed_trades.length > 0) {
            setLoading(false);
        }
    }, [closed_trades]);


    if (isLoading == false) {
        return (
            <React.Fragment>
                <div className="card-inner">
                    <div className="card-title-group">
                        <div className="card-title card-title-sm">
                            <h6 className="title">Trades</h6>

                        </div>
                        <div className="card-tools">
                            <ul className="card-tools-nav">
                                {roles.includes(5150) ? (
                                    <li className={TradesTab === "Open" ? "active" : ""} onClick={() => setTradesTab("Open")}>
                                        <a
                                            href="#open"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                            }}
                                        >
                                            <span>Open</span>
                                        </a>
                                    </li>
                                ) : (
                                    <div></div>
                                )}

                                <li className={TradesTab === "Closed" ? "active" : ""} onClick={() => setTradesTab("Closed")}>
                                    <a
                                        href="#closed"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <span>Closed</span>
                                    </a>
                                </li>
                                <li className={TradesTab === "Pending" ? "active" : ""} onClick={() => setTradesTab("Pending")}>
                                    <a
                                        href="#pending"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <span>Pending</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Block size="lg">



                    <PreviewCard>
                        <ReactDataTable data={tabledata} columns={tablecolumns} keyField="ticket" pagination />
                    </PreviewCard>



                </Block>
            </React.Fragment>
        )
    }
}
export default Trades_Table;


