import React, { useState, useEffect } from 'react'
import axios from "../../../api/axios";
import {Alert} from "reactstrap";
import Icon from "../../../assets/components/icon/Icon";

const NEW_PAYMENT = '/api/rtm/partner_payouts';

const Form_new_IB_Payment = () => {

    const [UserId, setUserId] = useState("");
    const [UserName, setUserName] = useState("");
    const [PaymentType, setPaymentType] = useState("lot_comission");
    const [TradingaccId, setTradingaccId] = useState("");
    const [TradingAccountName, setTradingAccountName] = useState("");
    const [Comment, setComment] = useState("");
    const [FieldName, setFieldName] = useState('Lot Fee');
    const [Threshold, setThreshold] = useState('Lot Fee');
    const [errMsg, setErrMsg] = useState('');


    useEffect(() => {
        setErrMsg('');
    }, [UserId, UserName, PaymentType, TradingaccId, TradingAccountName, Comment, Threshold])

    const create_payment = async (e) => {
        e.preventDefault();
        console.log(UserId);
        if (UserId != "" && UserName != "" && TradingaccId != "" && TradingAccountName!= "" && Comment!="" && Threshold!="")
        {
            var pf_amount = 0.0;
            var lot_share_amount = 0.0;

            if (PaymentType == "lot_comission")
            {
                lot_share_amount = Threshold;
            }else{
                pf_amount = Threshold;
            }

            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }

                const response = await axios.post(NEW_PAYMENT, {
                        "id": 0,
                        "user_login": UserId,
                        "user_name": UserName,
                        "payment_type": PaymentType,
                        "trading_account_id": TradingaccId,
                        "trading_account_name": TradingAccountName,
                        "payment_comment": Comment,
                        "amount_per_lot": lot_share_amount,
                        "pf_share": pf_amount,
                        "created_at": Date.now()
                    },
                    {headers: {
                            'Content-Type': 'application/json',
                            'Authorization': ['Bearer ', token].join('')
                        }});

                console.log(JSON.stringify(response));
                document.getElementById('UserID').value = "";
                document.getElementById('UserName').value = "";
                document.getElementById('TradingaccID').value = "";
                document.getElementById('TradingAccountName').value = "";
                document.getElementById('Comment').value = "";
                document.getElementById('Threshold').value = "";


            }
            catch (err)
            {
                console.log(err);
            }
        }
        else {
            setErrMsg("Please fill all Fields!");
        }
    }
    const changePaymentType = (e) => {

        setPaymentType(e.target.value);
        setErrMsg("test");
        if (e.target.value == "lot_comission")
        {
            setFieldName("Lot Fee");
        }
        else
        {
            setFieldName("Performance Fee");
        }
    }


    return(
        <React.Fragment>
            <div className="card-inner">
                <span className="preview-title-lg overline-title">Create Payments</span>
                <form action="#" onSubmit={create_payment}>
                    <div className="row gy-4">
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1">User Login ID</label>
                                <div className="form-control-wrap"><input type="number" className="form-control"
                                                                          id="UserID"
                                                                          onChange={(e) => setUserId(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1">User Name</label>
                                <div className="form-control-wrap"><input type="text" className="form-control"
                                                                          id="UserName"
                                                                          onChange={(e) => setUserName(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1">Comission Type</label>
                                <div className="form-control-wrap">
                                    <select name="alertype" className="form-control" id="alertype" onChange={(e) => changePaymentType(e)}>
                                    <option value="lot_comission">Lot Comission</option>
                                    <option value="pf_comission">% Performance Fee</option>
                                </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1">Trading Account ID</label>
                                <div className="form-control-wrap"><input type="number" className="form-control"
                                                                          id="TradingaccID"
                                                                          onChange={(e) => setTradingaccId(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1">Trading Account Name</label>
                                <div className="form-control-wrap"><input type="text" className="form-control"
                                                                          id="TradingAccountName"
                                                                          onChange={(e) => setTradingAccountName(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1">Comment</label>
                                <div className="form-control-wrap">
                                    <input type="text" className="form-control"
                                           id="Comment"
                                           onChange={(e) => setComment(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1">{FieldName}</label>
                                <div className="form-control-wrap"><input type="number"  step="0.01" className="form-control"
                                                                          id="Threshold"
                                                                          onChange={(e) => setThreshold(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group"><label className="form-label"
                                                               htmlFor="phone-no-1"></label>
                                <div className="form-control-wrap">
                                    <button className="btn btn-primary" type="submit">Create Payment</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="row gy-4">
                        {errMsg && (

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="form-control-wrap">
                                        <Alert color="danger" className="alert-icon">
                                            {" "}
                                            <Icon name="alert-circle" /> {errMsg}
                                        </Alert>

                                        </div>

                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default Form_new_IB_Payment