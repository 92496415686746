import React, { useState, Outlet, useEffect } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import Head from '../../components/head/Head';
import Content from '../../components/content/Content';
import axios from "../../api/axios";
import moment from 'moment';
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockTitle,
    PreviewAltCard,
    Icon,
    Button,
    Row,
    Col,
    TooltipComponent
} from "../../assets/components/Component";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import BalanceOverview from "./components/Portfolio_Equity_chart/ChartOverview";

import Portfolio_Alerts from "./components/Alerts/Portfolio_Alerts";
import PortfolioStatisttik from "./components/AccountStatistik/PortfolioStatisttik";
import Portfolio_Constitues_Cards from "./components/Portfolio_Constitutes/portfolio_constitutes";
import PortfolioMargin from "./components/Margin/PortfolioMargin";
import PortfolioDrawdown from "./components/Drawdown/PortfolioDrawdown";

const SpecificPortfolio = () => {
    const navigate = useNavigate();
    const params = useParams()
    const [portfolionames, setportfolioNames] = useState(JSON.parse(localStorage.getItem("portfolios")));
    const [roles, setRoles] = useState(localStorage.getItem('Roles'));

    return (
        <React.Fragment>
            <Head title="Portfolio Details" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Portfolio {params.portId} - {portfolionames[params.portId]}</BlockTitle>
                            <div className="nk-block-head-sub"><a className="back-to" href="/accounts"
                                                                  onClick={(ev) => {
                                                                      ev.preventDefault();
                                                                      navigate(-1);
                                                                  }}>
                                <em className="icon ni ni-arrow-left"></em><span>Portfolios</span></a></div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="g-gs">
                        <Col md="12" xxl="8">
                            <PreviewAltCard className="card-full">
                                <Portfolio_Constitues_Cards></Portfolio_Constitues_Cards>
                            </PreviewAltCard>
                        </Col>
                    </Row>
                    <Row className="g-gs">
                        {roles.includes(5150) ? (
                            <Col md="12" xxl="8">
                                {/*<InvestPlan />*/}
                                <PreviewAltCard className="card-full">
                                    <BalanceOverview></BalanceOverview>
                                </PreviewAltCard>
                            </Col>
                        ) : (
                            <div></div>
                        )}
                    </Row>


                    <Row className="g-gs">
                        {roles.includes(5150) ? (
                            <Col md="12" xxl="8">
                                {/*<InvestPlan />*/}
                                <PreviewAltCard className="card-full">
                                    <PortfolioStatisttik></PortfolioStatisttik>
                                </PreviewAltCard>
                            </Col>
                        ) : (
                            <div></div>
                        )}
                    </Row>

                    {roles.includes(5150) ? (
                    <Row className="g-gs">

                            <Col md="6" xxl="4">
                                {/*<InvestPlan />*/}
                                <PreviewAltCard className="card-full">
                                    <PortfolioMargin></PortfolioMargin>
                                </PreviewAltCard>
                            </Col>
                        <Col md="6" xxl="4">
                            {/*<InvestPlan />*/}
                            <PreviewAltCard className="card-full">
                                <PortfolioDrawdown></PortfolioDrawdown>
                            </PreviewAltCard>
                        </Col>

                    </Row>
                        ) : (
                            <div></div>
                        )}



                    <Row className="g-gs">
                        {roles.includes(5150) ? (
                            <Col md="12" xxl="8">
                                {/*<InvestPlan />*/}
                                <PreviewAltCard className="card-full">
                                    <Portfolio_Alerts></Portfolio_Alerts>
                                </PreviewAltCard>
                            </Col>
                        ) : (
                            <div></div>
                        )}
                    </Row>



                </Block>
            </Content>
        </React.Fragment>
    )

};

export default SpecificPortfolio;