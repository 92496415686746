import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import Icon from '../../../../assets/components/icon/Icon';
import {AudienceLineChart} from "../balance_equity_chart/BalanceChart";
import {CardTitle} from "reactstrap";
import salesOverview from "./Data";
import axios from "../../../../api/axios";
import {LineChart, BarChart} from "./Charts";

const ACCOUNT_STATISTIC = '/api/rtm/statistic/';
const ACCOUNT_RETURN = '/api/rtm/return_table/';
const MONTHLY_RETURN = '/api/rtm/return_monthly/'
const AccountStatistik = () => {
    const [cardTitel, setCardTitel] = useState("Statistics");
    const [statistics, setStatistics] = useState({ "total_winning_trades": 0,
        "account_id": 0,
        "average_win": 0,
        "best_trade": 0,
        "total_longs_winning_trades": 0,
        "total_trade_count": 0,
        "user_id": 0,
        "average_loss": -0,
        "worst_trade": -0,
        "total_shorts_winning_trades": 0})
    const [account_return, setAccountReturn] = useState({});
    const [monthly_return_data, setMonthlyReturn] = useState({})
    const [loaded, setLoaded] = useState(false);
    const params = useParams()

    function formatcurrencyFormat(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '1,')
    }

    function currencyFormat(amount){
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    useEffect(() => {
        async function fetchAccountStatistic() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ACCOUNT_STATISTIC + params.userId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setStatistics(await response?.data);

            }
            catch (e)
            {
                console.log(e);
            }
        }

        async function fetchAccountReturn() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ACCOUNT_RETURN + params.userId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});


                setAccountReturn({
                    labels: await response?.data.map(a => a.date),
                    dataUnit: "BTC",
                    datasets: [
                        {
                            label: "Return Overview",
                            color: "#798bff",
                            fill: true,
                            backgroundColor: "rgba(101,118,255,0.25)",
                            borderColor: "#798bff",
                            barPercentage: 0.1,
                            categoryPercentage: 0.1,
                            borderWidth: 2,
                            lineTension: 0.1,
                            pointBorderColor: "transparent",
                            pointBackgroundColor: "transparent",
                            pointHoverBorderColor: "#798bff",
                            pointHoverBackgroundColor: "#fff",
                            data: await response?.data.map(b => (b.total_return *100).toFixed(2)),
                        },
                    ],
                });



            }
            catch (e)
            {
                console.log(e);
            }
        }

        async function fetchMonthlyReturn() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(MONTHLY_RETURN + params.userId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});


                setMonthlyReturn(
                {
                    labels: await response?.data.map(a => a.year_month),
                        dataUnit: "USD",
                    stacked: false,
                    datasets: [
                    {
                        label: "Monthly Return",
                        backgroundColor: await response?.data.map((item, i)=> {
                            if (i === response.data.length - 1) {
                                return "rgba(101,118,255,1)";
                            }
                            else {
                                return "rgba(101,118,255,0.2)";
                            }
                        }),
                        data: await response?.data.map(a => (a.monthly_return * 100).toFixed(2)),
                    },
                ],
                }
                   );



            }
            catch (e)
            {
                console.log(e);
            }
        }



        fetchAccountStatistic();
        fetchAccountReturn();
        fetchMonthlyReturn();
        setLoaded(true);
    }, []);



    if (loaded == true)
    {
        return (
        <React.Fragment>
            <div className="card-inner">
                <div className="card-title-group">
                    <div className="card-title card-title-sm">
                        <h6 className="title">{cardTitel}</h6>

                    </div>
                    <div className="card-tools">
                        <ul className="card-tools-nav">
                            <li className={cardTitel === "Return" ? "active" : ""} onClick={() => setCardTitel("Return")}>
                                <a
                                    href="#Return"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Return</span>
                                </a>
                            </li>
                            <li className={cardTitel === "Monthly" ? "active" : ""} onClick={() => setCardTitel("Monthly")}>
                                <a
                                    href="#Monthly"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Monthly</span>
                                </a>
                            </li>
                            <li className={cardTitel === "Statistics" ? "active" : ""} onClick={() => setCardTitel("Statistics")}>
                                <a
                                    href="#Statistics"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Statistics</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>




                    {cardTitel == "Statistics" ? (
                        <div className="pricing-body">
                            <div className="row">
                                <div className="col">
                                    <ul className="pricing-features">
                                        <li><span className="w-50">Total Trades</span> <span className="ms-auto">{statistics.total_trade_count}</span></li>
                                        <li><span className="w-50">Total Trades won</span> <span className="ms-auto">{statistics.total_winning_trades}</span></li>
                                        <li><span className="w-50">Total Longs won</span> <span className="ms-auto">{statistics.total_longs_winning_trades}</span></li>
                                        <li><span className="w-50">Total Shorts won</span> <span className="ms-auto">{statistics.total_shorts_winning_trades}</span></li>
                                        <li><span className="w-50">Profit Factor</span> <span className="ms-auto">{(statistics.total_winning_trades / (statistics.total_trade_count - statistics.total_winning_trades)).toFixed(2)}</span></li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul className="pricing-features">
                                        <li><span className="w-50">Best Trade</span> <span className="text-success">{currencyFormat(statistics.best_trade)} </span> EUR</li>
                                        <li><span className="w-50">Worst Trade</span> <span className="text-danger">{currencyFormat(statistics.worst_trade)} </span> EUR</li>
                                        <li><span className="w-50">Average Win</span>  <span className="text-success">{currencyFormat(statistics.average_win)} </span> EUR</li>
                                        <li><span className="w-50">Average Loss</span>  <span className="text-danger">{currencyFormat(statistics.average_loss)} </span> EUR</li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    ): cardTitel == "Return" ? (
                        <div className="nk-sales-ck large pt-4">
                            <LineChart data={account_return}/>
                        </div>
                    ) : (
                        <div className="nk-sales-ck large pt-4"> <BarChart chartdata={monthly_return_data}/> </div>
                        )}

                <div className="chart-label-group ml-5">
                    <div className="chart-label"></div>
                    <div className="chart-label d-none d-sm-block"></div>
                    <div className="chart-label"></div>
                </div>

        </React.Fragment>
    );}
};
export default AccountStatistik;