import { useNavigate, Link } from "react-router-dom";
import { useContext,useEffect } from "react";
import AuthContext from "../../context/AuthProvider";




const Admin = () => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        document.body.className = 'body';
    }, []);
    return (
        <section>
            <div>
                <h1>Hello Admin</h1>
            </div>
        </section>
    )
}



export default Admin