const menudata = [
    {
        icon: "home",
        text: "Home",
        link: "/",
    },
    {
        icon: "coins",
        text: "Real Time Monitoring",
        active: false,
        subMenu: [
            {
                text: "Accounts",
                link: "/accounts",
            },
            {
                text: "Portfolios",
                link: "/portfolios",
            },
        ],
    },

];
export default menudata;
