import { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import brandlog from '../../assets/images/mag_brand_logo.png'
import axios from '../../api/axios';
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import Icon from './../Icon/Icons';
const LOGIN_URL = '/api/token';
const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [username, setUser] = useState('');
    const [password, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');


    useEffect(() => {
        setErrMsg('');
        document.body.className = 'nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme dark-mode';
    }, [username, password])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://admiring-bouman.195-201-61-139.plesk.page/api/token', qs.stringify({
                username: username,
                password: password,
            }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const accessToken = response?.data?.access_token;
            const roles = response?.data?.roles || [0, 1];
            setAuth({ username, password, roles, accessToken });
            setUser('');
            setPwd('');

            if (typeof window !== 'undefined') {
                localStorage.setItem('auth', JSON.stringify({ username, password, roles, accessToken }))
                localStorage.setItem('Token', accessToken);
                localStorage.setItem('Roles', roles);
            }

            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
                console.log(err);
            }
            errRef.current.focus();
        }
    }

    const Testfunction = async (e) => {

    }

    return (

        <section>
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content">
                    <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                        <div className="brand-logo pb-4 text-center">
                            <a className="logo-link" href="/">
                                <img className="logo-light logo-img logo-img-lg" src={brandlog}></img>
                            </a>
                        </div>

                        <div className="card-preview card-bordered card">
                            <div className="card-inner card-inner-lg">
                                <div className="nk-block-head">
                                    <div className="nk-block-content"><h4 className="nk-block-title title">Sign-In</h4>
                                        <div className="nk-block-des"><p>Access Dashboard using your email and password.</p>
                                        </div>
                                    </div>
                                </div>
                                <form className="is-alter" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <div className="form-label-group">
                                            <label className="form-label" htmlFor="default-01">Email</label>
                                        </div>
                                        <div className="form-control-wrap">
                                            <input type="text" id="username"
                                                placeholder="Enter your email address or username"
                                                ref={userRef}
                                                onChange={(e) => setUser(e.target.value)}
                                                value={username}
                                                required
                                                className="form-control-lg form-control" />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-label-group">
                                            <label className="form-label" htmlFor="password">Password</label>
                                        </div>
                                        <div className="form-control-wrap">
                                            <input type="password"
                                                id="password"
                                                placeholder="Enter your password"
                                                className="form-control-lg form-control is-shown"
                                                onChange={(e) => setPwd(e.target.value)}
                                                value={password}
                                                required
                                            />

                                        </div>
                                    </div>
                                    {errMsg && (
                                        <div className="mb-3">
                                            <Alert color="danger" className="alert-icon">
                                                {" "}
                                                <Icon name="alert-circle" /> Unable to login with credentials{" "}
                                            </Alert>
                                        </div>
                                    )}

                                    <div className="form-group">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Sign in</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Login