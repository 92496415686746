import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";

import LogoLight2x from "../../assets/images/mag_logo.png";
import LogoDark2x from "../../assets/images/mag_logo.png";
import { Link, useNavigate } from "react-router-dom";
import Button from '../../assets/components/button/Button';
import Icon from '../../assets/components/icon/Icon';
import AuthContext from "../../context/AuthProvider";
import { useContext, useEffect } from "react";
const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
    const headerClass = classNames({
        "nk-header": true,
        "nk-header-fixed": fixed,
        [`is-light`]: theme === "white",
        [`is-${theme}`]: theme !== "white" && theme !== "light",
        [`${className}`]: className,
    });
    const navigate = useNavigate();
    const { setAuth } = useContext(AuthContext);
    const logout = async () => {
        // if used in more components, this should be in context
        // axios to /logout endpoint
        if (typeof window !== 'undefined') {
            localStorage.removeItem('Token');
        }
        console.log("logout");
        setAuth({});
        navigate('/');
    }

    return (
        <div className={headerClass}>
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger d-xl-none ml-n1">
                        <Toggle
                            className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
                            icon="menu"
                            click={props.sidebarToggle}
                        />
                    </div>
                    <div className="nk-header-brand d-xl-none">
                        <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
                            <img className="logo-light logo-img" src={LogoLight2x} alt="logo" />
                            <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />
                        </Link>
                    </div>
                    <div className="nk-header-news d-none d-xl-block">
                        {/* <News /> */}

                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="notification-dropdown mr-n1" onClick={logout}>
                                {/* <Notification />*/}
                                <Button color="primary" size="" className="btn-round">
                                    <Icon name="signout" />
                                    <span>Logout</span>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header;
