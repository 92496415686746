import React, { useEffect, useState } from "react";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    PreviewCard,
    ReactDataTable,
} from "../../../assets/components/Component";
import { Link, useParams } from "react-router-dom";
import axios from "../../../api/axios"
const IB_PAYMENTS = '/api/rtm/partner_payouts';
const DELETE_IB_PAYMENTS = '/api/rtm/partner_payouts/';
const IB_Payment_Table = () => {
    const [tabledata, setTableData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const dataTableColumns = [
        {
            name: "#",
            selector: (row) => row.id,
            sortable: true,
            hide: "sm",
        },
        {
            name: "User ID",
            selector: (row) => row.user_login,
            sortable: true,
            hide: 370,
        },
        {
            name: "Name",
            selector: (row) => row.user_name,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Type",
            selector: (row) => row.payment_type,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Trading Account ID",
            selector: (row) => row.trading_account_id,
            sortable: true,
            hide: "md",
        },
        {
            name: "Trading Account Name",
            selector: (row) => row.trading_account_name,
            sortable: true,
            hide: "md",
        },
        {
            name: "Comment",
            selector: (row) => row.payment_comment,
            sortable: true,
            hide: "md",
        },
        {
            name: "Lot Fee",
            selector: (row) => row.amount_per_lot,
            sortable: true,
            hide: "md",
        },
        {
            name: "PF Share",
            selector: (row) => row.pf_share,
            sortable: true,
            hide: "md",
        },
        {
            name: "Created",
            selector: (row) => row.created_at,
            sortable: true,
            hide: "md",
        },
        {
          name: "Delete",
          cell:(row)=><button onClick={clickHandler} id={row.id}>Delete</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        async function fetch_IB_Payments() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(IB_PAYMENTS, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });
                setTableData(await response?.data)

            }
            catch (e) {
                console.log(e);
            }
        }

        fetch_IB_Payments();

    }, []);

    useEffect(() => {
        if (tabledata.length > 0) {
            setLoading(false);
        }
    }, [tabledata]);

    const clickHandler = async (state) => {
        console.log(state.target.id);
        try {
            var token = "";
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('Token');
            }

            const response = await axios.delete(DELETE_IB_PAYMENTS + state.target.id, {headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': ['Bearer ', token].join('')
                }});

            console.log(JSON.stringify(response));

            var payments_tmp = tabledata;
            payments_tmp.splice(payments_tmp.findIndex(a => a.id === state.target.id) , 1);
            setTableData(payments_tmp);
            tabledata.splice(tabledata.findIndex(a => a.id === state.target.id) , 1);
        }
        catch (err)
        {
            console.log(err);
        }
    };

    if (isLoading == false) {
        return (
            <React.Fragment>
                <PreviewCard>
                    <ReactDataTable data={tabledata} columns={dataTableColumns} keyField="id" expandableRows pagination actions id="DataTableIBPayments"/>
                </PreviewCard>
            </React.Fragment>
        )
    }

}
export default IB_Payment_Table;
