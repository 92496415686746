import React, {useState, useEffect} from "react";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockTitle,
    PreviewAltCard,
    PreviewCard,
    Icon,
    Button,
    Row,
    Col,
    TooltipComponent
} from "../../../../assets/components/Component";
import {findUpper} from "../../../../utils/Utils";
import UserAvatar from '../../../../assets/components/user/UserAvatar';
import {Link, useParams} from "react-router-dom";
import {Card, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle} from "reactstrap";
import axios from "../../../../api/axios";

const PORTFOLIO_MEMBERS = '/api/rtm/portfolio_constitutes/';

const Portfolio_Constitues_Cards = () => {

    const [loaded, setLoaded] = useState(false);
    const [cardData, setcardData] = useState([]);
    const params = useParams()
    useEffect(() => {
        async function fetchPortfolioConsts() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(PORTFOLIO_MEMBERS + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setcardData(await response?.data);

            } catch (e) {
                console.log(e);
            }
        }

        fetchPortfolioConsts();

        setLoaded(true);
    }, []);

    function currencyFormat(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    if (loaded == true) {
        return (
            <React.Fragment>
                <div className="card-title-group pb-3 g-2">
                    <div className="card-title card-title-sm">
                        <h6 className="title">Constituents</h6>
                        <p>In this section you can find all portfolio members and analyze the individual performance by
                            clicking on one of the accounts.</p>
                    </div>
                </div>
                    <div className="card-inner">
                        <div className="row">
                            {cardData.map((item) => {
                                return (
                                    <Col md="6" lg="4">
                                        <PreviewAltCard>
                                            <div className="team">
                                                <div className="user-card user-card-s2">
                                                    <UserAvatar theme="purple" className="md"
                                                                text={findUpper(item.name)}>
                                                        <div className="status dot dot-lg dot-success"></div>
                                                    </UserAvatar>
                                                    <div className="user-info">
                                                        <h6>{item.name}</h6>
                                                        <span className="sub-text">{item.trading_account_id}</span>
                                                    </div>
                                                </div>
                                                <ul className="team-statistics">
                                                    <li>
                                                        <span>{currencyFormat(item.trading_account_balance)}</span>
                                                        <span>Balance</span>
                                                    </li>
                                                    <li>
                                                        <span>{(item.account_return_total * 100).toFixed(2)}%</span>
                                                        <span>Performed</span>
                                                    </li>
                                                    <li>
                                                        <span>{item.total_trades_account}</span>
                                                        <span>Trades</span>
                                                    </li>
                                                    <li>
                                                        <span>{(item.trading_account_balance / item.portfolio_balance *100).toFixed(2)}%</span>
                                                        <span>Portfolio Share</span>
                                                    </li>

                                                </ul>
                                                <div className="team-view">
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/accounts/` + item.trading_account_id}
                                                        className="btn btn-round btn-outline-light"
                                                    >
                                                        <span>View Account</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </PreviewAltCard>
                                    </Col>)
                            })}
                        </div>
                    </div>



            </React.Fragment>
        )
    }
}
export default Portfolio_Constitues_Cards;